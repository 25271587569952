//Cancellations screen

<template>
<v-app id="app">
    <TitleBar v-bind:title="activeTitle" />
    <v-main id="v-main">
        <v-container v-on:click="touch">
            <router-view />
            <ScanAlert v-if="scanAlertType != ''" v-bind:type="scanAlertType" />
        </v-container>
    </v-main>

    <div id="bottomUI">
        <ActionBar v-if="actionText != ''" v-bind:text="actionText" />
        <NavBar v-if="showNavbar" />
    </div>

    <div id="blocking-loader" v-if="this.$store.state.loading">
        <div class="d-flex align-center justify-center" id="loader-container">
            <div id="loader-contents">
                <img src="@/assets/img/wtr-load.gif" class="max-width pb-1" id="loader-img" />
                <span id="loader-text">Please wait...</span>
            </div>
        </div>
    </div>
</v-app>
</template>

<style lang="scss">
@import "@/assets/scss/main.scss";

#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

#nav {
    padding: 30px;

    a {
        font-weight: bold;
        color: #2c3e50;

        &.router-link-exact-active {
            color: #42b983;
        }
    }
}

#v-main {
    margin-top: 12vh;
    margin-bottom: 12vh;
}

#bottomUI {
    position: fixed;
    z-index: 1;
    width: 100%;
    bottom: 0;
}

#blocking-loader {
    position: fixed;
    z-index: 5;
    width: 100%;
    height: 100%;
    background-color: rgba(43, 44, 44, 0.65);
}

#loader-container {
    height: 100%;
}

#loader-contents {
    max-width: 50%;
    background-color: #FFFFFF;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 1rem;
}

#loader-text {
    margin-top: 2rem;
}

#loader-img {
    max-width: 100%;
}
</style>

<script>
import TitleBar from "@/components/TitleBar.vue"
import NavBar from "@/components/NavBar.vue"
import ActionBar from "@/components/ActionBar.vue"

import vars from "@/assets/scripts/vars.js"
import ScanAlert from "./components/ScanAlert.vue"

const axios = require("axios").default

export default {
    components: {
    TitleBar,
    NavBar,
    ActionBar,
    ScanAlert
},
    computed: {
        activeTitle() {
            return this.$store.state.activeTitle
        },
        showNavbar() {
            return this.$store.state.showNavbar
        },
        actionText() {
            return this.$store.state.actionText
        }
    },
    created() {
        document.addEventListener('keypress', this.onKeyPress);
        this.scan = ""

        document.addEventListener('scanAlert', this.scanAlertEvent)
    },
    mounted() {
        this.ordersService()  
        this.stockService() 
        this.pickService()
    },
    data: function() {
        return {
            scan: "",
            timer: 0,
            dispatchCount: 0,
            scannerActivated: true,
            scanAlertType: ""
        }
    },
    methods: {
        scanAlertEvent: function( e ) {
            let audio = null
            let delay = 500

            switch(e.detail.type){
                case "warning":
                    audio = new Audio("/assets/audio/warning.mp3")
                    audio.play()
                    window.navigator.vibrate(200)
                    break
                case "good":
                    audio = new Audio("/assets/audio/good.mp3")
                    audio.play()
                    window.navigator.vibrate(50)
                    break
                case "bad":
                    delay = 1000
                    audio = new Audio("/assets/audio/bad.mp3")
                    audio.play()
                    window.navigator.vibrate(1000)
                    break
            }

            this.scanAlertType = e.detail.type
            setTimeout(() => {
                this.scanAlertType = ""
            }, delay)
        },
        touch: function(){
            if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
                this.$fullscreen.request(document.documentElement)
            }
            this.clickNoise()
        },
        
        ordersService: function(){
            if(this.$store.state.user != null && this.$store.state.pendingChanges <= 0){
                axios.get(vars.apiURL + "orders/all/" + this.$store.state.user.branch, {
                    "headers": {
                        "Authorization": "Bearer " + this.$store.state.user.access_token
                    }
                }).then((response) => {
                    //Make O(1) lookup
                    let orders = {}
                    response.data.forEach((order) => {
                        orders[order.orderNumber] = order
                    })
                    this.$store.commit("setOrders", orders)

                    setTimeout(() => {
                        this.ordersService()
                    }, 10000)
                }).catch((error) => {
                    console.log(error)
                    setTimeout(() => {
                        this.ordersService()
                    }, 1000)
                })
            }else{
                //Redirect to login
                if(this.$router.currentRoute.name != "Login" && this.$store.state.user == null){
                    this.$router.push({name: "Login"})
                }

                setTimeout(() => {
                        this.ordersService()
                    }, 1000)
            }
        },

        stockService: function(){
            if(this.$store.state.user != null && this.$store.state.pendingChanges <= 0) {
                axios.get(vars.apiURL + "items/entertaining/all?branch=" + this.$store.state.user.branch, {
                    "headers": {
                        "Authorization": "Bearer " + this.$store.state.user.access_token
                    }
                }).then((res) => {
                    //Make O(1) lookup
                    let stock = {}
                    res.data.forEach((item) => {
                        stock[item.lineNumber] = item
                    })
                    this.$store.commit("setStock", stock)
                    setTimeout(() => {
                        this.stockService()
                    }, 5000)
                }).catch((err) => {
                    console.log(err)
                    setTimeout(() => {
                        this.stockService()
                    }, 1000)
                })
            }else{
                setTimeout(() => {
                        this.stockService()
                    }, 1000)
            }
        },

        pickService: function(){
            if(this.$store.state.user != null && this.$store.state.pendingChanges <= 0){
                axios.get(vars.apiURL + "picklist/all/" + this.$store.state.user.branch, {
                    "headers": {
                        "Authorization": "Bearer " + this.$store.state.user.access_token
                    }
                }).then((res) => {
                    this.$store.commit("setActivePicks", res.data)
                    setTimeout(() => {
                        this.pickService()
                    }, 2000)
                }).catch((err) => {
                    console.log(err)
                    setTimeout(() => {
                        this.pickService()
                    }, 1000)
                })
            }else{
                setTimeout(() => {
                        this.pickService()
                    }, 1000)
            }
        },
        
        /*assortmentService: function() {
            //DEBUG ONLY
            var xd = true
            if(this.$store.state.pendingChanges == 0){
                if (this.$store.state.partnerNumber != "" || xd) {
                    //User logged in
                    //Get picks
                    axios.get(vars.apiURL + "assortment-barcodes", {
                        "headers": {
                            "Authorization": this.$store.state.partnerNumber
                        }
                    }).then((res) => {
                        if (res.status == 200) {
                            this.$store.commit("setAssortment", res.data)
                        }
                    }).catch((err) => {
                        console.error(err)
                    })
                }
            }
        },
        stockService: function() {
            //DEBUG ONLY
            var xd = true
            if(this.$store.state.pendingChanges == 0){
                if (this.$store.state.partnerNumber != "" || xd) {
                    //User logged in
                    //Get stock
                    axios.get(vars.apiURL + "stock", {
                        "headers": {
                            "Authorization": this.$store.state.partnerNumber
                        }
                    }).then((res) => {
                        if (res.status == 200) {
                            this.$store.commit("setStock", res.data)
                        }
                    }).catch((err) => {
                        console.error(err)
                    })
                }
            }
        },
        pickService: function() {
            //Changes pending?
            if (this.$store.state.pendingChanges == 0) {
                //DEBUG ONLY
                var xd = true
                if (this.$store.state.partnerNumber != "" || xd) {
                    //User logged in
                    //Get picks
                    axios.get(vars.apiURL + "pick", {
                        "headers": {
                            "Authorization": this.$store.state.partnerNumber
                        }
                    }).then((res) => {
                        if (res.status == 200) {
                            //Any new picks?
                            var currentPicks = []
                            var newPick = false
                            this.$store.state.activePicks.forEach((pick) => {
                                currentPicks.push(pick.orderIdentifier)
                            })
                            res.data.forEach((pick) => {
                                if (!currentPicks.includes(pick.orderIdentifier)) {
                                    newPick = true
                                }
                            })

                            if (newPick) {
                                var audio = new Audio("/assets/audio/new-pick.mp3")
                                audio.play()
                                window.navigator.vibrate(200)
                            }

                            this.$store.commit("setActivePicks", res.data)
                        }
                    }).catch((err) => {
                        console.error(err)
                    })
                }
            }
        },*/


        clickNoise: function() {
            var audio = new Audio("/assets/audio/click.mp3")
            audio.play()
        },
        onKeyPress: function(e) {
            if(document.activeElement.tagName != "INPUT"){
                this.scan += e.key

                var localScan = this.scan

                setTimeout(() => {
                    if(this.scan == localScan){
                        //Shit's not changed. Dispatch!
                        if(this.scan != null){
                            var evnt = new CustomEvent("keyInputXd", {
                                bubbles: false,
                                cancelable: false,
                                detail: {
                                    value: this.scan
                                }
                            })
                            document.dispatchEvent(evnt)
                        }
                        this.scan = ""
                    }
                }, 100)
            }
        }
    }
}
</script>
