<template>
    <div>
        <v-row class="align-left mx-2">
            <h1>Prepare substitutions</h1>
            <p>This shows you all items where you are at least 1 unit short. You should not review this
                until you have received your expected delivery for the day, or you are sure the items are not coming in.
            </p>
            <v-select outlined :items="dateOptions" v-model="selectedDate" label="Date" v-on:change="getSubs()">
                
            </v-select>
        </v-row>
        <!--Items begin-->
        <!--v-on:click="findSub(item)" (maybe next year)-->
        <p class="mt-6 green rounded" v-if="shortedItems.length == 0">No substitutions to prepare. Hooray!</p>

        <v-card v-for="item in shortedItems" v-bind:key="item.lineNumber" class="mb-5 mt-5 py-5 px-2">
            <v-row justify="center" class="d-flex">
                <v-col cols="3" class="pb-0 pt-0 pl-3" align-self="center">
                    <v-img
                        v-bind:src="'https://ecom-su-static-prod.wtrecom.com/images/products/11/LN_' + item.lineNumber.toString().padStart(6, '000000') + '_BP_11.jpg'" />
                </v-col>
                <v-col cols="9" class="pb-0 pr-0 pt-0 align-left" align-self="center">
                    <v-row>
                        <v-col cols="12" class="pb-0 pt-2 pr-0 pl-0">
                            <p class="mb-title item-title text-h4">{{ getItemTitle(item.lineNumber) }}</p>
                        </v-col>
                    </v-row>
                    <v-row class="mt-0">
                        <v-col cols="12" class="pb-0 pt-0 pr-0 pl-0">
                            <strong class="text-h5">{{ item.lineNumber }}</strong>
                        </v-col>
                    </v-row>
                    <v-row class="mt-0">
                        <v-col cols="12" class="pt-0 pb-1 mt-2 pr-0 pl-0">
                            <small class="mb-desc text-h6">{{item.entCollection}} needed for Ent. collections</small><br/>
                            <small class="mb-desc text-h6">{{item.grocery}} needed for grocery orders</small><br/>
                            <small class="mb-desc text-h6">{{item.quantityInStock}} currently in stock</small><br/>
                            <small class="mb-desc text-h6 yellow">Short by {{item.quantityShort}}</small>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-card>

    </div>
</template>
    
<script>
import moment from 'moment'
import axios from 'axios'
import vars from "@/assets/scripts/vars.js"

export default {
    name: 'PrepareSubstitutions',
    data: () => {
        return {
            shortedItems: [],
            dateOptions: [
                {
                    "text": "20/12/2024",
                    "value": "2024-12-20T00:00:00"
                },
                {
                    "text": "21/12/2024",
                    "value": "2024-12-21T00:00:00"
                },
                {
                    "text": "22/12/2024",
                    "value": "2024-12-22T00:00:00"
                },
                {
                    "text": "23/12/2024",
                    "value": "2024-12-23T00:00:00"
                },
                {
                    "text": "24/12/2024",
                    "value": "2024-12-24T00:00:00"
                }
            ],
            selectedDate: {
                "text": moment().format("DD/MM/YYYY"),
                "value": moment().format("YYYY-MM-DD") + "T00:00:00"
            }
        }
    },
    components: {
        
    },
    beforeMount() {
        this.$store.commit("setTitle", "Prepare Substitutions")
        this.$store.commit("enableNavbar", "true")
        this.$store.commit("setActionText", "")
    },
    mounted() {
        this.getSubs()
    },
    methods: {
        getSubs: function(){
            this.$store.commit("setLoading", true)
            axios({
                "method": "GET",
                "url": vars.apiURL + "stock/entertainingStockRequired/" + this.$store.state.user.branch + "?date=" + moment(this.selectedDate).format("YYYY-MM-DDTHH:mm:ss"),
                "headers": {
                    "Authorization": "Bearer " + this.$store.state.user.access_token
                }
            }).then((data) => {
                let requiredItems = data.data
                // Figure out shortages
                requiredItems.forEach((item) => {
                    item.quantityRequired = item.entCollection + item.grocery
                    let quantityInStock = 0
                    this.$store.state.stock[item.lineNumber].stockRecords.forEach((stockRecord) => {
                        quantityInStock += stockRecord.quantity
                    })
                    item.quantityInStock = quantityInStock
                    item.quantityShort = item.quantityRequired - item.quantityInStock
                })

                this.shortedItems = requiredItems.filter((item) => {
                    return item.quantityShort > 0
                })

                // Sort by biggest shortage
                this.shortedItems.sort((a, b) => {
                    return b.quantityShort - a.quantityShort
                })

                this.$store.commit("setLoading", false)

            }).catch((error) => {
                console.log(error)
            })

        },
        findSub: function(item) {
            this.$store.commit("setActiveSubItem", item.lineNumber)
            this.$router.push("/prepare-substitutions/find-substitution")
        },
        findNeededItems: function () {
            let requiredItems = {}

            Object.keys(this.$store.state.orders).forEach((orderNumber) => {
                let order = this.$store.state.orders[orderNumber]

                //Only look at order if being collected tomorrow
                //)
                if(moment(order.collectionSlotStart).isSame(moment(), "day")){
                    order.items.forEach((item) => {
                        if (Object.keys (requiredItems).includes(item.lineNumber)) {
                            requiredItems[item.lineNumber] += item.quantity
                        } else {
                            requiredItems[item.lineNumber] = item.quantity
                        }
                    })   
                }
            })

            //Now compare against stock levels
            let shortedItems = {}

            Object.keys(requiredItems).forEach((requiredItem) => {
                //Process current stock level
                let quantityInStock = 0
                this.$store.state.stock[requiredItem].stockRecords.forEach((stockRecord) => {
                    quantityInStock += stockRecord.quantity
                })

                if (requiredItems[requiredItem] > quantityInStock) {
                    shortedItems[requiredItem] = [requiredItems[requiredItem] - quantityInStock, quantityInStock]
                }
            })

            //WE ALSO NEED TO CONSIDER ITEMS WHICH HAVE ALREADY BEEN SUBBED

            return shortedItems
        },
        getSlotFormatted: function (timestamp) {
            return moment(timestamp).format("D/MM/YY HH:mm")
        },
        getTotalOrderQty: function (order) {
            let count = 0
            order.items.forEach((item) => {
                count += item.quantity
            })
            return count
        },
        getTotalUnavailableQty: function (lowItems) {
            let count = 0
            lowItems.forEach((item) => {
                count += item[2] - item[1]
            })
            return count
        },
        getUnfulfillableOrders: function () {
            let unfulfillableOrders = []
            Object.keys(this.$store.state.orders).forEach((orderNumber) => {
                let order = this.$store.state.orders[orderNumber]
                let lowItems = []
                order.items.forEach((item) => {
                    let stockRecords = this.$store.state.stock[item.lineNumber].stockRecords
                    let requiredStock = item.quantity
                    let stockAvailable = 0
                    stockRecords.forEach((stockRecord) => {
                        stockAvailable += stockRecord.quantity
                    })
                    if (stockAvailable < requiredStock) {
                        lowItems.push([item, stockAvailable, requiredStock])
                    }
                })
                if (lowItems.length > 0) {
                    unfulfillableOrders.push({
                        "order": order,
                        "lowItems": lowItems
                    })
                }
            })
            return unfulfillableOrders
        },
        getItemTitle: function(lineNumber) {
            return this.$store.state.stock[lineNumber].title
        }
    }
}
</script>
    